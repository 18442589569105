import * as React from "react";
import { FC } from "react";
import {
  Heading,
  HEADING_CLASS
} from "../../common/components/typography/heading";
import { Paragraph } from "../../common/components/typography/paragraph";

interface ILegalSectionProps {
  title: string;
  paragraphs: string[];
}

export const LegalSection: FC<ILegalSectionProps> = ({ title, paragraphs }) => (
  <>
    <Heading level={2} theme={HEADING_CLASS.SECTION}>
      {title}
    </Heading>
    <div>
      {paragraphs.map((paragraph, index) => (
        <Paragraph key={`paragraph${index}`}>{paragraph}</Paragraph>
      ))}
    </div>
  </>
);
