import * as React from "react";
import { FC, ReactNode } from "react";
import classes from "./paragraph.module.css";

interface IParagraphProps {
  children: ReactNode;
}

export const Paragraph: FC<IParagraphProps> = ({ children }) => (
  <p className={classes.paragraph}>{children}</p>
);
