import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { LegalSection } from "../components/legal/legal-section";

export const LegalEntrypoint: FC<ILocalizedPage> = _props => {
  const title = useLocalized("legal_title");
  const sections = [
    {
      title: useLocalized("legal_section1_title"),
      paragraphs: [useLocalized("legal_section1_paragraph")]
    },
    {
      title: useLocalized("legal_section2_title"),
      paragraphs: [useLocalized("legal_section2_paragraph")]
    },
    {
      title: useLocalized("legal_section3_title"),
      paragraphs: [
        useLocalized("legal_section3_paragraph1"),
        useLocalized("legal_section3_paragraph2")
      ]
    },
    {
      title: useLocalized("legal_section4_title"),
      paragraphs: [useLocalized("legal_section4_paragraph")]
    }
  ];
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      {sections.map(section => (
        <LegalSection title={section.title} paragraphs={section.paragraphs} />
      ))}
    </>
  );
};
