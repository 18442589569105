import * as React from "react";
import { FC } from "react";
import { LegalEntrypoint } from "../../entrypoints/legal";
import { LANG } from "../../common/types/localization";
import { DefaultLayout } from "../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.deCH}
    entrypoint={"legal"}
    page={"rechtliche_hinweise"}
  >
    <LegalEntrypoint lang={LANG.deCH} />
  </DefaultLayout>
);
